/* You can add global styles to this file, and also import other style files */
*
    margin: 0
    padding: 0

body
    background: url("/assets/img/background.jpg") no-repeat top center fixed
    background-attachment: fixed
    -webkit-background-size: cover
    -moz-background-size: cover
    -o-background-size: cover
    background-size: cover
    max-height: 100%
    width: 100%

.container
    position: absolute
    width: 100%
    max-height: 100%

.container-component
    height: auto
    width: 100%
    margin-top: 60px
    margin-bottom: 60px

body.removeBackground
    background: none

@keyframes fadeInAnimation
    0%
        opacity: 0
    100%
        opacity: 1

button.action-button
    padding: 15px
    border-radius: 30px
    border: 0
    background: #FCD94A
    font-size: 14px
    margin: auto
    cursor: pointer
    font-family: sans-serif

app-footer
    position: static
    background: #030200

a.link
    text-decoration: none
    color: white
    cursor: pointer
    font-weight: 600

p.white_space
    margin-bottom: 80px !important

.copyright
    font-size: 12px

h1.title
    font-size: 40px
    color: white
    margin: 0 0 30px
    font-weight: 300
    font-family: fantasy

.sm
    cursor: pointer

section#contact
    width: 100%